var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-row',{staticClass:"pt-3",attrs:{"align":"center"}},[_c('v-col',[_c('span',{staticClass:"heading-h2",attrs:{"data-testid":"export-people-title"}},[_vm._v(" "+_vm._s(_setup.t('Title_.page.exportPeople'))+" ")])])],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"rounded-lg shadow-regular py-8 px-7"},[_c('v-container',{staticClass:"mb-6"},[_c('v-row',[_c('v-col',{staticClass:"pa-0 heading-h3",attrs:{"data-testid":"export-people-subtitle"}},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.fields'))+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0 subtitle-text-3",attrs:{"data-testid":"export-people-message"}},[_vm._v(" "+_vm._s(_setup.t('Msg_.exportExplanation'))+" ")])],1)],1),_vm._l((_setup.exportStore.exportPeopleFields),function(item,key){return _c('v-container',{key:key},[_c('v-row',[_c('v-col',{staticClass:"pa-0 mb-4 d-flex justify-space-between"},[_c('div',{staticClass:"d-flex"},[_c('div',{class:'d-flex align-center',attrs:{"data-testid":"checkbox-control"}},[_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"dense":"","hide-details":"","color":"blueRegular"},on:{"change":function($event){return _setup.selectAllFields(
                        $event,
                        _setup.exportStore.exportPeopleFields[key].fields
                      )}},model:{value:(_setup.exportStore.exportPeopleFields[key].isSelected),callback:function ($$v) {_vm.$set(_setup.exportStore.exportPeopleFields[key], "isSelected", $$v)},expression:"exportStore.exportPeopleFields[key].isSelected"}}),_c('span',{staticClass:"subtitle-text-2"},[_vm._v(" "+_vm._s(_setup.t(`Msg_.${item.name}`))+" ")]),_c('span',{staticClass:"ml-3 caption-3"},[_vm._v(_vm._s(_setup.checkItemSelection(item)))])],1)]),_c(_setup.IconButton,{staticClass:"icon-btn icon-color",attrs:{"data-testid":`export-people-${item.name}-icon`,"border":false,"icon":_setup.exportStore.exportPeopleFields[key].isExpanded
                    ? 'mdi-chevron-up'
                    : 'mdi-chevron-down',"icon-size":"25","color":"black"},on:{"click":function($event){return _setup.toggleExpandItem(_setup.exportStore.exportPeopleFields[key])}}})],1)],1),_c('v-expand-transition',[(_setup.exportStore.exportPeopleFields[key].isExpanded)?_c('v-container',{staticClass:"mt-2"},[_c('v-row',_vm._l((_setup.exportStore.exportPeopleFields[
                    key
                  ].fields),function(field,fieldKey){return _c('v-col',{key:fieldKey,staticClass:"pa-0 d-flex",attrs:{"cols":"6"}},[_c(_setup.CheckboxControl,{staticClass:"body-text-4",attrs:{"data-testid":`export-people-${key}-${fieldKey}-check-box`,"label":_setup.t(`Msg_.${field.name}`),"disabled":field.isRequired},model:{value:(field.isSelected),callback:function ($$v) {_vm.$set(field, "isSelected", $$v)},expression:"field.isSelected"}})],1)}),1)],1):_vm._e()],1)],1)}),_c('v-container',[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"pa-0 d-flex align-center"},[_c('div',{staticClass:"ml-auto section-detail mineBlack--text",attrs:{"data-testid":`export-people-export-message`}},[_vm._v(" "+_vm._s(`${_setup.t('Msg_.exportContains')} ${ _setup.exportStore.numberEmployees } ${_setup.t('Msg_.people')}`)+" ")])]),_c('v-col',{staticClass:"pa-0 ml-4",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"main-action-btn",attrs:{"data-testid":`export-people-export-button`,"loading":_setup.isExporting},on:{"click":_setup.exportPeople}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.exportResults'))+" ")])],1)],1)],1)],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }