import { defineStore } from 'pinia';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { ref } from 'vue';

export const useExportStore = defineStore('export', () => {
  const mainStore = useMainStore();
  const loadingStore = useLoadingStore();

  // --- State ---
  const exportPeopleFields = ref({});
  const numberEmployees = ref(null);

  // --- Methods ---
  const getPeopleFields = async () => {
    const params = {
      endpoint: 'v2/api/export/employees/fields',
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getPeopleFields');
    try {
      const response = await mainStore.request(params);
      exportPeopleFields.value = prepareData(response);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getPeopleFields');
    }
  };

  const prepareData = (data) => {
    const payload = [];
    for (const key in data) {
      const item = {
        name: key,
        isSelected: false,
        isExpanded: false,
        fields: prepareFields(data[key]),
      };
      payload.push(item);
    }
    return payload;
  };

  const prepareFields = (fields) => {
    return fields.map((field) => {
      field.isSelected = false;
      field.name = handleFieldName(field.name);
      if (field.isRequired) field.isSelected = true;
      return field;
    });
  };

  const handleFieldName = (fieldName) => {
    if (fieldNameIsAcronym(fieldName)) {
      return fieldName.toLowerCase();
    } else {
      return fieldName[0].toLowerCase() + fieldName.substring(1);
    }
  };

  const fieldNameIsAcronym = (fieldName) => {
    return /^[A-Z]+$/.test(fieldName);
  };

  const getNumberEmployees = async () => {
    const params = {
      endpoint: 'v2/api/export/employees/stats',
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getTotalRecords');
    try {
      const response = await mainStore.request(params);
      numberEmployees.value = response.numberOfEmployees;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getTotalRecords');
    }
  };

  return {
    exportPeopleFields,
    getPeopleFields,
    getNumberEmployees,
    numberEmployees,
  };
});
