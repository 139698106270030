<template>
  <v-container>
    <v-row align="center" class="pt-3">
      <v-col>
        <span data-testid="export-people-title" class="heading-h2">
          {{ t('Title_.page.exportPeople') }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="rounded-lg shadow-regular py-8 px-7">
          <v-container class="mb-6">
            <v-row>
              <v-col
                data-testid="export-people-subtitle"
                class="pa-0 heading-h3"
              >
                {{ t('Title_.subTitle.fields') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                data-testid="export-people-message"
                class="pa-0 subtitle-text-3"
              >
                {{ t('Msg_.exportExplanation') }}
              </v-col>
            </v-row>
          </v-container>
          <v-container
            v-for="(item, key) in exportStore.exportPeopleFields"
            :key="key"
          >
            <v-row>
              <v-col class="pa-0 mb-4 d-flex justify-space-between">
                <div class="d-flex">
                  <div
                    :class="'d-flex align-center'"
                    data-testid="checkbox-control"
                  >
                    <v-checkbox
                      class="ma-0 pa-0"
                      dense
                      hide-details
                      color="blueRegular"
                      v-model="exportStore.exportPeopleFields[key].isSelected"
                      @change="
                        selectAllFields(
                          $event,
                          exportStore.exportPeopleFields[key].fields
                        )
                      "
                    />
                    <span class="subtitle-text-2">
                      {{ t(`Msg_.${item.name}`) }}
                    </span>
                    <span class="ml-3 caption-3">{{
                      checkItemSelection(item)
                    }}</span>
                  </div>
                </div>
                <IconButton
                  :data-testid="`export-people-${item.name}-icon`"
                  class="icon-btn icon-color"
                  :border="false"
                  :icon="
                    exportStore.exportPeopleFields[key].isExpanded
                      ? 'mdi-chevron-up'
                      : 'mdi-chevron-down'
                  "
                  icon-size="25"
                  color="black"
                  @click="toggleExpandItem(exportStore.exportPeopleFields[key])"
                />
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-container
                v-if="exportStore.exportPeopleFields[key].isExpanded"
                class="mt-2"
              >
                <v-row>
                  <v-col
                    v-for="(field, fieldKey) in exportStore.exportPeopleFields[
                      key
                    ].fields"
                    :key="fieldKey"
                    cols="6"
                    class="pa-0 d-flex"
                  >
                    <CheckboxControl
                      :data-testid="`export-people-${key}-${fieldKey}-check-box`"
                      class="body-text-4"
                      :label="t(`Msg_.${field.name}`)"
                      v-model="field.isSelected"
                      :disabled="field.isRequired"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-expand-transition>
          </v-container>
          <v-container>
            <v-row class="mt-4">
              <v-col class="pa-0 d-flex align-center">
                <div
                  :data-testid="`export-people-export-message`"
                  class="ml-auto section-detail mineBlack--text"
                >
                  {{
                    `${t('Msg_.exportContains')} ${
                      exportStore.numberEmployees
                    } ${t('Msg_.people')}`
                  }}
                </div>
              </v-col>
              <v-col class="pa-0 ml-4" cols="auto">
                <v-btn
                  :data-testid="`export-people-export-button`"
                  class="main-action-btn"
                  @click="exportPeople"
                  :loading="isExporting"
                >
                  {{ t('Action_.buttons.exportResults') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import languages from '@/components/profile/locales/languages';
import CheckboxControl from '@/components/shared/customComponents/controls/checkbox-control.vue';
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';
import { ref } from 'vue';
import { useExportStore } from '@/store/pinia/export-people.store.js';
import { useI18n } from 'vue-i18n-bridge';

const exportStore = useExportStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const isExporting = ref(false);

const exportPeople = async () => {
  const payload = prepareToExport();
  const url = `${process.env.VUE_APP_LOGIN_API}/v2/api/export/employees`;
  const today = new Date();
  const formattedDate = today.toISOString().slice(0, 10).replace(/-/g, '_');
  const filename = `export_mitarbeiter_${formattedDate}.csv`;

  const configs = {
    method: 'POST',
    body: JSON.stringify({ fields: payload }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  try {
    isExporting.value = true;
    const response = await fetch(url, configs);
    // Extract the CSV from the response as a blob
    const blob = await response.blob();
    // Create a temporary URL that points to the blob
    const downloadUrl = URL.createObjectURL(blob);
    // Create a new anchor tag to trigger the file download
    const downloadLink = document.createElement('a');
    // Set the "href" to the temporary URL
    downloadLink.href = downloadUrl;
    // Set the "download" attribute to the filename
    downloadLink.download = filename;
    // Programmatically click to start the download
    downloadLink.click();
    // Release the temporary URL to free up memory
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.log(error);
  }
  isExporting.value = false;
};

const prepareToExport = () => {
  let payload = [];

  exportStore.exportPeopleFields.forEach((item) => {
    item.fields.forEach((field) => {
      if (field.isSelected) payload = [...payload, field.value];
    });
  });

  return payload;
};

const toggleExpandItem = (item) => {
  item.isExpanded = !item.isExpanded;
};

const selectAllFields = (event, fields) => {
  fields.forEach((field) => {
    if (!event && !field.isRequired) {
      field.isSelected = false;
    } else {
      field.isSelected = true;
    }
  });
};

const checkItemSelection = (item) => {
  const count = item.fields.reduce((acc, { isSelected }) => {
    return isSelected ? acc + 1 : acc;
  }, 0);

  item.isSelected = count === item.fields.length;

  return `${count} ${t(`Msg_.of`)} ${item.fields.length} ${t(`Msg_.selected`)}`;
};

// --- Lifecycle hooks ---
exportStore.getPeopleFields();
exportStore.getNumberEmployees();
</script>

<style scoped lang="scss">
.mdi-chevron-down::before {
  color: black !important;
}
</style>
